.plan-gradient {
    min-width: 280px;
    max-width: 280px;
    height: fit-content;
}

.plan-gradient:hover  {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.083) 0%, rgba(255, 255, 255, 0.312) 100%);
}

.plan-popular {
    max-width: fit-content !important;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    background: linear-gradient(135deg, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0.00) 0%);
}
.plan-popular .card-gradient-content {
    min-width: 50px !important;
    padding: 5px 30px !important;
    height: 29px !important;
    
    flex-direction: row;
    justify-content: center;
    max-width: fit-content !important;
}

/* Plan lista idx-0 */ 
.plan-0 .card-gradient-content {
    min-width: 280px;
    max-width: 280px;
    padding: 30px 20px;
    scroll-snap-align: center;
    display: flex;
    width: 100%;
    height: fit-content;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 97%), rgb(95, 131, 249) 60%),         
        linear-gradient(109deg, rgb(84 63 212), rgba(95, 131, 249) 60%);
    background-size: 100% 2px;
}
.plan-0 .card-gradient-content::before,
.plan-0 .card-gradient-content::after {
    border: 2px solid #543FD4;
    border-right: none;
}
.plan-0 .card-gradient-content::after {
    border: 2px solid #5F83F9;
    border-left: none;
}

/* Plan lista idx-1 */ 
.plan-1 .card-gradient-content {
    min-width: 280px;
    max-width: 280px;
    padding: 30px 20px;
    scroll-snap-align: center;
    display: flex;
    width: 100%;
    height: fit-content;
    background-image: 
        linear-gradient(to right, rgb(84 63 212), rgb(205 173 92) 100%),         
        linear-gradient(109deg, rgb(84 63 212), rgba(205, 173, 92) 100%);
    background-size: 100% 2px;
}
.plan-1 .card-gradient-content::before,
.plan-1 .card-gradient-content::after {
    border: 2px solid #5f48cb;
    border-right: none;
    width: 20px;
}
.plan-1 .card-gradient-content::after {
    border: 2px solid #CDAD5C;
    border-left: none;
}

/* Plan lista idx-2 */ 
.plan-2 .card-gradient-content {
    min-width: 280px;
    max-width: 280px;
    padding: 20px 20px 30px 20px;
    scroll-snap-align: center;
    display: flex;
    width: 100%;
    height: fit-content;
    background-image: 
        linear-gradient(to right, rgb(255 199 0 / 100%), rgb(241 104 18) 100%), 
        linear-gradient(109deg, rgb(255 199 0 / 100%), rgb(241 104 18) 100%);
    background-size: 100% 2px;
}
.plan-2 .card-gradient-content::before,
.plan-2 .card-gradient-content::after {
    width: 20px;
    border: 2px solid #FFC700;
    border-right: none;
}
.plan-2 .card-gradient-content::after {
    width: 20px;
    border: 2px solid #F16812;
    border-left: none;
}

.plan {
    display: flex;

    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    scroll-snap-align: center;
}

.plan-body-first {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.plan-header .small-bold {
    font-size: 1.125rem;
    font-family: 'FuturaPT-Bold';
}

.plan-price .big-header {
    font-size: 2.375rem;
    font-family: 'FuturaPT-Medium';
}

.plan-text {
    color: var(--white);
}

.plan-header-price {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.plan-header {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.plan-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px
}

.plan-body{
    text-align: justify;
}

.plan-button {
    /* background-image: url(../../../public/images/png/selectBackground.png); */
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
}

.plan-button .button {
    padding: 10px 25px;
    border-radius: 24px;
}

.plan-button .button span {
    font-size: 0.875rem;
    font-family: 'Futura-Bold';
    color: var(--white);
}

.plan-announcement {
    text-align: center;
}  

.plan-footer {
    text-align: end;
}
.plan-detail-item {
    display: flex;
    flex-direction: column;
    gap: 20px
}